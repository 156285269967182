import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../data-access";
import { useUser } from "../../hooks";
import { FormButton } from "../../ui";
import { PipedriveForm } from "./pipedrive-form";
import "./tools-and-apps.scss";
import { AIJobResearcher } from "./ai-job-researcher";
import { ToolCard } from "./tool-card";
import { Popover } from "antd";

export const ToolsAndApps = (): JSX.Element => {
  const navigate = useNavigate();
  const [isPipedriveLoading, setIsPipedriveLoading] = useState<
    boolean | undefined
  >(undefined);
  const [isAIJobReasearcherLoading, setIsAIJobReasearcherLoading] =
    useState<boolean>(false);
  const { currentUser } = useUser();

  const isUserOnStripePlans =
    currentUser &&
    currentUser?.license?.is_stripe &&
    (currentUser?.license?.plan === "Starter" ||
      currentUser?.license?.plan === "Rising Talent");

  const isAvailableAPI =
    currentUser &&
    currentUser?.paid_user &&
    currentUser?.license?.is_stripe &&
    currentUser?.license?.plan !== "Starter" &&
    currentUser?.license?.plan !== "Rising Talent";

  const handleApiRoute = (): void => {
    if (isAvailableAPI) {
      navigate(`${APP_ROUTES.api}`);
    }
  };

  return (
    <div className="tools-and-apps">
      <h2 className="tools-and-apps__section-title">CRM & API</h2>
      <div className="tools-and-apps__ai-tools">
        <ToolCard
          title="Pipedrive"
          picture="./pipedrive_logo.svg"
          isLoading={isPipedriveLoading}
          footerComponent={
            isUserOnStripePlans ? (
              <FormButton disabled>Install</FormButton>
            ) : (
              <PipedriveForm
                onRequest={(isLoading: boolean) =>
                  setIsPipedriveLoading(isLoading)
                }
              />
            )
          }
        >
          {isUserOnStripePlans ? (
            <>
              This functionality is not available for your plan. Please upgrade
              your{" "}
              <NavLink className="link" to={APP_ROUTES.plans}>
                plan
              </NavLink>{" "}
              to unlock it.
            </>
          ) : (
            <>
              Syncs data from Upwork to Pipedrive CRM, providing an easy
              integration between the platforms.
            </>
          )}
        </ToolCard>

        <ToolCard
          title="Upwex API"
          picture="./api-svgrepo-com.svg"
          footerComponent={
            isAvailableAPI ? (
              <FormButton onClick={handleApiRoute} disabled={!isAvailableAPI}>
                Keys
              </FormButton>
            ) : (
              <Popover
                placement="topLeft"
                content={
                  "Upwex API is available only for tariff plans - Top Rated, Top Rated Plus, Expert-Vetted, Yearly"
                }
              >
                <FormButton className="disabled">Keys</FormButton>
              </Popover>
            )
          }
        >
          Seamlessly integrate Upwex's API & Webhooks into your platform.
        </ToolCard>
      </div>

      <h2 className="tools-and-apps__section-title">Tools</h2>
      <div className="tools-and-apps__ai-tools">
        <ToolCard
          title="AI Job Researcher"
          picture="./ai-lead.svg"
          isLoading={isAIJobReasearcherLoading}
          footerComponent={
            <AIJobResearcher onLoading={setIsAIJobReasearcherLoading} />
          }
        >
          Automatically finds and alerts you to the best job matches based on
          your skills and preferences <b>($49/month)</b>
        </ToolCard>

        <ToolCard
          title="AI Autobidder"
          picture="./autobidder.svg"
          isSoon={true}
          footerComponent={<></>}
        >
          The Upwex AI AutoBidder is a significant asset for freelancers hoping
          to succeed and be proficient on the world’s biggest freelancing
          platform.
        </ToolCard>

        <ToolCard
          title="AI Upwork Notifier"
          picture="./notify.svg"
          isSoon={true}
          footerComponent={<></>}
        >
          AI Upwork Notifier is a tool for automatically tracking replies and
          invitations with notifications in Telegarm/Slack.
        </ToolCard>
      </div>
    </div>
  );
};

export default ToolsAndApps;
