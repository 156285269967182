import React, { useEffect, useState } from "react";
import { BackButton, FormButton, InformationBlock, Panel } from "../../ui";
import { KeysTable } from "./keys-table";
import { WebhookForm } from "./webhook-url";
import "./upwex-api.scss";
import { useDispatch } from "react-redux";
import { storeModalsActions } from "../../store/slices/modals";
import { APP_ROUTES, ModalType } from "../../data-access";
import { developerApi } from "../../api/developer-tools";

const baseClass = "upwex-api";

export const UpwexApi = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);
  const [keys, setKeys] = useState<any[]>([]);

  const requestKeys = (): void => {
    setIsProcessingRequest(true);
    developerApi
      .getKeys()
      .then((response) => {
        const { data } = response;
        setKeys(data);
      })
      .finally(() => setIsProcessingRequest(false));
  };

  useEffect(() => {
    requestKeys();
  }, []);

  const showInvitationModal = (): void => {
    dispatch(
      storeModalsActions.open({
        type: ModalType.UpwexApiKey,
        props: { onSuccess: requestKeys },
      })
    );
  };

  return (
    <div className={baseClass} style={{ paddingTop: "24px" }}>
      <BackButton to={APP_ROUTES.toolsAndApps}>Back to Tools & Apps</BackButton>

      <Panel title="Upwex API">
        <p style={{ marginBottom: "24px" }}>
          The Upwex API allows you to seamlessly integrate Upwex functionality
          into your custom applications or CRM systems. With this API, you can
          access user data, team information, Upwork profiles, and various
          analytics, making it easier to manage and monitor your workflow
          efficiently.
          <br />
          <br />
          Additionally, our webhooks provide real-time notifications for key
          events such as job openings, bid submissions, replies, and invites,
          allowing you to automate responses and keep your processes running
          smoothly.
        </p>
        <InformationBlock>
          To dive deeper into how our API works, including endpoints,
          parameters, and real-time data integrations, please refer to our
          detailed{" "}
          <a
            href="https://help.upwex.io/en/articles/upwex-api-webhooks-guide/"
            target="_blank"
          >
            API documentation
          </a>
          .
        </InformationBlock>
      </Panel>

      <Panel
        title="Keys"
        nodeInTitleRow={
          <FormButton className="invite-button" onClick={showInvitationModal}>
            + Create new key
          </FormButton>
        }
      >
        <KeysTable
          isLoading={isProcessingRequest}
          keys={keys}
          onDelete={requestKeys}
        />
      </Panel>

      <Panel title="Webhook URL">
        <WebhookForm />
      </Panel>
    </div>
  );
};

export default UpwexApi;
