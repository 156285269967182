import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormButton, ModalDialog } from "../../ui";
import { handleFailedRequest, notify } from "../../util";
import { IResponseError } from "../../data-access";
import { developerApi } from "../../api/developer-tools";
import { Button, Input, Space } from "antd";
import { FaRegCopy } from "react-icons/fa";

import "./upwex-api.scss";

interface IUpwexApiKeyModalProps {
  onClose: () => void;
  onSuccess?: () => void;
}

const baseClass = "upwex-api-modal";

export const UpwexApiKeyModal = ({
  onClose,
  onSuccess,
}: IUpwexApiKeyModalProps): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [openKey, setOpenKey] = useState<string>("");
  const inputEl = useRef(null);

  const handleCopy = (ref: any) => {
    ref.select();
    ref.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(openKey);
    notify("success", "API key have been copied!");
  };

  const handleCreateKey = (): void => {
    developerApi
      .createKey()
      .then((response) => {
        const {
          data: { access_key },
        } = response;
        setOpenKey(access_key);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessing(false));
  };

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  };

  const handleDone = () => {
    onClose();
    onSuccess && onSuccess();
  };

  return (
    <ModalDialog
      className="invite-team-member-modal"
      title={!openKey ? "Create a New API Key" : "Save your key"}
      isOpen={true}
      onCancel={handleClose}
    >
      {!openKey ? (
        <>
          <p className={`${baseClass}__text`}>
            To generate a new API key, simply click the Create button below.
            This key will allow you to access the Upwex API for integrating with
            your applications. Make sure to store your key securely, as it
            grants access to important data and functionality.
            <br />
          </p>
          <FormButton
            className={`${baseClass}__close`}
            disabled={isProcessing}
            loading={isProcessing}
            onClick={handleCreateKey}
          >
            Create
          </FormButton>
        </>
      ) : (
        <>
          <p className={`${baseClass}__text`}>
            Please save this secret key somewhere safe and accessible. For
            security reasons, <b>you won't be able to view it again</b> through
            your Upwex account. If you lose this secret key, you'll need to
            generate a new one.
          </p>
          <Space.Compact className={`${baseClass}__actions`}>
            <Input value={openKey} disabled ref={inputEl} />
            <Button type="primary" onClick={() => handleCopy(inputEl.current)}>
              <FaRegCopy /> Copy
            </Button>
          </Space.Compact>
          <FormButton
            className={`${baseClass}__close`}
            disabled={isProcessing}
            theme="brand"
            onClick={handleDone}
          >
            Done
          </FormButton>
        </>
      )}
    </ModalDialog>
  );
};

UpwexApiKeyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};
