import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./data-table-pagination.scss";
import { DOTS, useCustomPagination } from "./usePagination";

interface IDataTablePaginationProps {
  perPage: number;
  perPageOptions: number[];
  total: number;
  currentPage: number;
  onPagination: (perPage: number, requestedPage: number) => void;
  isSpecificPagination?: boolean;
}

const baseClassName = "data-table-pagination";

export function DataTablePagination({
  perPage,
  perPageOptions,
  currentPage,
  isSpecificPagination,
  onPagination,
  total,
}: IDataTablePaginationProps): JSX.Element | null {
  const totalPages = Math.ceil(total / perPage);

  const isPerPageSelectorEnabled = total > perPageOptions[0];
  const isPageSelectorEnabled = totalPages > 1;
  let paginationRange: any[] = [];
  let lastPage = 0;

  paginationRange =
    useCustomPagination({
      currentPage,
      totalCount: total,
      siblingCount: 1,
      pageSize: perPage,
    }) || [];
  lastPage = paginationRange[paginationRange.length - 1];

  if (!isPerPageSelectorEnabled && !isPageSelectorEnabled) {
    return null;
  }

  const onNext = () => {
    onPagination(perPage, currentPage + 1);
  };

  const onPrevious = () => {
    onPagination(perPage, currentPage - 1);
  };

  return (
    <div className={baseClassName}>
      {isPerPageSelectorEnabled && (
        <span className={`${baseClassName}__per-page-selector`}>
          Showing{" "}
          <select
            defaultValue={perPage}
            onChange={(e) => onPagination(+e.target.value, currentPage)}
          >
            {perPageOptions.map((recordsPerPage) => (
              <option
                key={`record-per-page-${recordsPerPage}`}
                value={recordsPerPage}
              >
                {recordsPerPage}
              </option>
            ))}
          </select>{" "}
          of {total} entries
        </span>
      )}
      {isPageSelectorEnabled && isSpecificPagination && (
        <ul className={`pagination-container`}>
          <li
            className={`pagination-item arror ${
              currentPage === 1 ? "disabled" : ""
            }`}
            onClick={onPrevious}
          >
            <div className="arrow left" />
          </li>
          {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return <li className="pagination-item dots">&#8230;</li>;
            }

            return (
              <li
                className={`pagination-item ${
                  pageNumber === currentPage ? "selected" : ""
                }`}
                onClick={() => onPagination(perPage, +pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}
          <li
            className={`pagination-item arror ${
              currentPage === lastPage ? "disabled" : ""
            }`}
            onClick={onNext}
          >
            <div className="arrow right" />
          </li>
        </ul>
      )}
      {isPageSelectorEnabled && !isSpecificPagination && (
        <div className={`${baseClassName}__page-selector`}>
          <button
            disabled={currentPage === 1}
            onClick={() => onPagination(perPage, currentPage - 1)}
          >
            <LeftOutlined />
          </button>
          <button
            disabled={currentPage === totalPages}
            onClick={() => onPagination(perPage, currentPage + 1)}
          >
            <RightOutlined />
          </button>
        </div>
      )}
    </div>
  );
}
