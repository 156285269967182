import React, { useEffect, useState } from "react";
import {
  BotStatusColor,
  IGetSettingsResponse,
  IResponseError,
} from "../../../data-access";
import { FormButton, Panel } from "../../../ui";
import { notify, handleFailedRequest } from "../../../util";
import "./job-bot-status.scss";
import { Tag } from "antd";
import { researcherApi } from "../../../api/job-researcher";

interface IJobBotStatusProps {
  isFetching: boolean;
  settings: any;
  onChange: (value: boolean) => void;
}

const baseClass = "job-bot-status";

export const JobBotStatus = ({
  isFetching,
  settings = {},
  onChange,
}: IJobBotStatusProps): JSX.Element => {
  const [isBtnStartLoading, setIsBtnStartLoading] = useState<boolean>(false);
  const [isBtnStopLoading, setIsBtnStopLoading] = useState<boolean>(false);
  const [botStatus, setBotStatus] = useState<any>({
    is_start: true,
    is_stopped: true,
    status: "",
  });
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);

  useEffect(() => {
    if (settings && settings.login) {
      setIsFormComplete(true);

      researcherApi
        .checkJobResearcherTaskBotStatus("1")
        .then((response: any) => {
          const data = response.data;
          if (data.status === "error" || data.status === "stopped") {
            setStoppedState(data.status);
          } else {
            setStartingState(data.status);
          }
        })
        .catch((error: IResponseError) => {
          setStoppedState("stopped");
        });
    }
  }, [settings]);

  useEffect(() => {
    let getIntervalId: ReturnType<typeof setInterval> | string = "";

    getIntervalId = setInterval(() => {
      if (settings && settings.login) {
        setIsFormComplete(true);

        researcherApi
          .checkJobResearcherTaskBotStatus("1")
          .then((response: any) => {
            const data = response.data;
            if (data.status === "error" || data.status === "stopped") {
              setStoppedState(data.status);
            } else {
              setStartingState(data.status);
            }
          })
          .catch((error: IResponseError) => {
            setStoppedState("stopped");
          });
      }
    }, 30000);

    return () => {
      getIntervalId && clearInterval(getIntervalId);
    };
  }, [settings]);

  const setStoppedState = (status: string): void => {
    setBotStatus({
      status: status,
      is_start: false,
      is_stopped: true,
    });
    onChange(false);
  };

  const setStartingState = (status: string): void => {
    setBotStatus({
      is_start: true,
      is_stopped: false,
      status: status,
    });
    onChange(true);
  };

  const handleStart = (): void => {
    setIsBtnStartLoading(true);
    researcherApi
      .startJobResearcherTaskBot(1)
      .then((response: IGetSettingsResponse) => {
        notify("success", "Bot have been successfully started.");
        setBotStatus({
          ...botStatus,
          is_start: true,
          is_stopped: false,
          status: "active",
        });
        onChange(true);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsBtnStartLoading(false));
  };

  const handleStop = (): void => {
    setIsBtnStopLoading(true);
    researcherApi
      .stopJobResearcherTaskBot(1)
      .then((response: IGetSettingsResponse) => {
        notify("success", "Bot have been successfully stopped.");
        setBotStatus({
          ...botStatus,
          is_start: false,
          is_stopped: true,
          status: "stopped",
        });
        onChange(false);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsBtnStopLoading(false));
  };

  const buttonComponent = (
    <div className={`${baseClass}__info-item`}>
      <FormButton
        className={`${baseClass}__button`}
        onClick={handleStart}
        loading={isBtnStartLoading}
        disabled={botStatus.is_start || !isFormComplete}
      >
        Start Bot
      </FormButton>
      <FormButton
        className={`${baseClass}__button`}
        loading={isBtnStopLoading}
        onClick={handleStop}
        disabled={botStatus.is_stopped}
      >
        Stop Bot
      </FormButton>
    </div>
  );

  const subtitleComponent = botStatus.status ? (
    <Tag
      color={BotStatusColor[botStatus.status as keyof typeof BotStatusColor]}
    >
      {botStatus.status}
    </Tag>
  ) : (
    <></>
  );

  return !isFetching ? (
    <Panel
      title="Bot:"
      subtitle={subtitleComponent}
      nodeInTitleRow={buttonComponent}
      className={`profile-stats-panel ${isFetching ? "is-loading" : ""}`}
    >
      <div className={`${baseClass}`}></div>
    </Panel>
  ) : (
    <></>
  );
};

export default JobBotStatus;
