import { Popover } from "antd";
import { Spinner } from "../../../ui";
import "./tool-card.scss";
import { FaInfoCircle } from "react-icons/fa";

interface IPlanCardProps {
  title: string;
  picture?: string;
  instruction?: string;
  children: any;
  footerComponent: any;
  isLoading?: boolean;
  isSoon?: boolean;
  showTooltip?: boolean;
}

const baseClassName = "tool-card";

export const ToolCard = ({
  picture,
  title,
  instruction,
  showTooltip = false,
  children,
  footerComponent,
  isLoading = false,
  isSoon = false,
}: IPlanCardProps): JSX.Element => {
  return (
    <div className={baseClassName}>
      {isLoading && <Spinner />}
      {isSoon && <div className={`${baseClassName}__soon`}>Soon</div>}
      <div className={`${baseClassName}__body`}>
        <div className={`${baseClassName}__content`}>
          <div className={`${baseClassName}__info`}>
            {picture && (
              <img
                className={`${baseClassName}__image`}
                src={picture}
                alt={title}
              />
            )}
            <span className={`${baseClassName}__title`}>
              {title}
              {showTooltip && (
                <Popover
                  placement="topLeft"
                  content={instruction}
                  trigger="hover"
                >
                  <FaInfoCircle />
                </Popover>
              )}
            </span>
          </div>

          <div className={`${baseClassName}__actions`}>{footerComponent}</div>
        </div>
        <div className={`${baseClassName}__desc`}>{children}</div>
      </div>
    </div>
  );
};
