import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { toolsApi } from "../../../api";
import { IResponseError } from "../../../data-access";
import { storeToolsActions } from "../../../store/slices/tools";
import { FormButton } from "../../../ui";
import { handleFailedRequest, notify } from "../../../util";

const baseClass = "pipedrive-form";

interface IPipedriveFormProps {
  onRequest: (isLoading: boolean) => void;
}

export const PipedriveForm = ({
  onRequest,
}: IPipedriveFormProps): JSX.Element => {
  const [isFetchingAuthStatus, setIsFetchingAuthStatus] =
    useState<boolean>(true);
  const [isFetchingAuthUrl, setIsFetchingAuthUrl] = useState<boolean>(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState<boolean>(false);
  const [isPipedriveConnected, setIsPipedriveConnected] = useState<
    boolean | undefined
  >(undefined);
  const [authUrl, setAuthUrl] = useState<string>("");

  const isProcessingRequests =
    isFetchingAuthStatus || isFetchingAuthUrl || isProcessingDelete;

  const dispatch = useDispatch();

  const handleDeleteClick = (): void => {
    setIsProcessingDelete(true);

    toolsApi
      .deletePipedrive()
      .then(() => {
        notify("success", "Pipedrive has been successfully disconnected.");
        setIsPipedriveConnected(false);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingDelete(false));
  };

  const handleConnectClick = (): void => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };

  const getPipedriveStatus = (): void => {
    toolsApi
      .getPipedriveStatus()
      .then((response: { data: { is_connected: boolean } }) => {
        const isConnected = response.data.is_connected;
        dispatch(
          storeToolsActions.setPipedriveConnection({
            is_connected: isConnected,
          })
        );
        setIsPipedriveConnected(isConnected);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsFetchingAuthStatus(false));
  };

  const getPipedriveAuthUrl = (): void => {
    setIsFetchingAuthUrl(true);
    toolsApi
      .getPipedriveAuthUrl()
      .then((response: { data: { url: string } }) => {
        setAuthUrl(response.data.url);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsFetchingAuthUrl(false));
  };

  useEffect(() => {
    getPipedriveStatus();
  }, []);

  useEffect(() => {
    if (isPipedriveConnected === false) {
      getPipedriveAuthUrl();
    }
  }, [isPipedriveConnected]);

  useEffect(() => {
    onRequest(isProcessingRequests);
  }, [isProcessingRequests, onRequest]);

  return (
    <div className={`${baseClass} row`}>
      {isPipedriveConnected ? (
        <div className={`${baseClass}__available`}>Connected</div>
      ) : (
        <FormButton className="tool__button" onClick={handleConnectClick}>
          Connect
        </FormButton>
      )}
      {isPipedriveConnected && (
        <FormButton
          theme="red"
          disabled={isProcessingRequests}
          className="tool__delete-button"
          onClick={handleDeleteClick}
        >
          <FaTrash />
        </FormButton>
      )}
    </div>
  );
};

PipedriveForm.propTypes = {
  onRequest: PropTypes.func.isRequired,
};
