import { baseApiPath, deleteEntity, get, post, put } from "./base-api";

const getKeys = (): Promise<any> => {
  return get(`${baseApiPath}/api/developer-tools/access-keys`);
};

const getWebhookURL = (): Promise<any> => {
  return get(`${baseApiPath}/api/developer-tools/webhook-url`);
};

const createKey = (): Promise<any> => {
  return post(`${baseApiPath}/api/developer-tools/access-keys`, {});
};

const createWebhookUrl = (url: string): Promise<any> => {
  return post(`${baseApiPath}/api/developer-tools/webhook-url`, { url });
};

const deleteKey = (id: string): Promise<any> => {
  return deleteEntity(`${baseApiPath}/api/developer-tools/access-keys/${id}`);
};

const deleteWebhook = (): Promise<any> => {
  return deleteEntity(`${baseApiPath}/api/developer-tools/webhook-url`);
};

export const developerApi = {
  getKeys,
  getWebhookURL,
  createKey,
  createWebhookUrl,
  deleteKey,
  deleteWebhook,
};
