import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAISettings,
  ICrmSettings,
  IJobScoreSettings,
  IJobSettings,
} from "../../data-access";

const INITIAL_STATE = {
  crm: {},
  jobs: {},
  job_assistant: {},
  jobScore: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: INITIAL_STATE,
  reducers: {
    setCrmSettings: (
      state = INITIAL_STATE,
      action: PayloadAction<ICrmSettings>
    ) => {
      return {
        ...state,
        crm: {
          ...action.payload,
        },
      };
    },
    setJobsSettings: (
      state = INITIAL_STATE,
      action: PayloadAction<IJobSettings>
    ) => {
      return {
        ...state,
        jobs: {
          ...action.payload,
        },
      };
    },
    setAISettings: (
      state = INITIAL_STATE,
      action: PayloadAction<IAISettings>
    ) => {
      return {
        ...state,
        job_assistant: {
          ...action.payload,
        },
      };
    },
    setJobScoreSettings: (
      state = INITIAL_STATE,
      action: PayloadAction<IJobScoreSettings>
    ) => {
      return {
        ...state,
        jobScore: {
          ...action.payload,
        },
      };
    },
    resetSettingsData: () => ({ ...INITIAL_STATE }),
  },
});

// this is for dispatch
export const storeSettingsActions = settingsSlice.actions;

// this is for configureStore
const settingsReducer = settingsSlice.reducer;

export default settingsReducer;
