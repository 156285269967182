import { baseApiPath, deleteEntity, get, post, put } from "./base-api";

const getUpworkProfiles = (): Promise<any> => {
  return get(`${baseApiPath}/api/upwork/profiles`);
};

const getJobResearcherTasks = (): Promise<any> => {
  return get(`${baseApiPath}/api/jobs-researcher/tasks`);
};

const getJobResearcherTaskById = (id: string): Promise<any> => {
  return get(`${baseApiPath}/api/jobs-researcher/tasks/${id}`);
};

const createJobResearcherTask = (data: any): Promise<any> => {
  return post(`${baseApiPath}/api/jobs-researcher/tasks`, data);
};

const updateJobResearcherTask = (id: number, data: any): Promise<any> => {
  return put(`${baseApiPath}/api/jobs-researcher/tasks/${id}`, data);
};

const deleteJobResearcherTask = (id: string): Promise<any> => {
  return deleteEntity(`${baseApiPath}/api/jobs-researcher/tasks/${id}`);
};

const getJobResearcherTaskActivity = (
  id: string,
  currentPage = 1,
  perPage = 10
): Promise<any> => {
  return get(
    `${baseApiPath}/api/jobs-researcher/tasks/${id}/activity?page=${currentPage}&page_size=${perPage}`
  );
};

const startJobResearcherTaskBot = (id: number): Promise<any> => {
  return post(`${baseApiPath}/api/jobs-researcher/tasks/${id}/bot/start`, {});
};

const checkJobResearcherTaskBotStatus = (id: string): Promise<any> => {
  return get(`${baseApiPath}/api/jobs-researcher/tasks/${id}/bot/status`);
};

const stopJobResearcherTaskBot = (id: number): Promise<any> => {
  return post(`${baseApiPath}/api/jobs-researcher/tasks/${id}/bot/stop`, {});
};

const checkJobResearcherTaskUpworkCreds = (
  id: number,
  data: any
): Promise<any> => {
  return post(
    `${baseApiPath}/api/jobs-researcher/tasks/${id}/check-upwork-creds`,
    data
  );
};

const getJobResearcherTaskJobs = (
  id: string,
  bidFilter = "",
  currentPage = 1,
  perPage = 10
): Promise<any> => {
  const potentialBidParam = bidFilter ? `&potential_bid=${bidFilter}` : "";
  return get(
    `${baseApiPath}/api/jobs-researcher/tasks/${id}/jobs?page=${currentPage}&page_size=${perPage}${potentialBidParam}`
  );
};

export const researcherApi = {
  getUpworkProfiles,
  getJobResearcherTasks,
  getJobResearcherTaskById,
  createJobResearcherTask,
  updateJobResearcherTask,
  deleteJobResearcherTask,
  getJobResearcherTaskActivity,
  startJobResearcherTaskBot,
  checkJobResearcherTaskBotStatus,
  stopJobResearcherTaskBot,
  checkJobResearcherTaskUpworkCreds,
  getJobResearcherTaskJobs,
};
