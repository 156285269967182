import React, { useState } from "react";
import moment from "moment";

import { DataTable, FormButton } from "../../../ui";
import { ColumnAlign } from "../../../data-access";
import { developerApi } from "../../../api/developer-tools";
import { FaRegTrashAlt } from "react-icons/fa";
import { notify } from "../../../util";
import { Modal } from "antd";

import "@patternfly/react-core/dist/styles/base.css";
import "./keys-table.scss";

const TABLE_COLUMNS = [
  { displayName: "Key", textAlign: ColumnAlign.LEFT },
  { displayName: "Creator" },
  { displayName: "Date" },
  { displayName: "", textAlign: ColumnAlign.RIGHT },
];
const baseClassName = "keys-table";

interface IKeysTableProps {
  keys: any[];
  isLoading: boolean;
  onDelete?: () => void;
}

export const KeysTable = ({
  isLoading,
  keys,
  onDelete,
}: IKeysTableProps): JSX.Element => {
  const { confirm: confirmAction } = Modal;
  const handlePagination = (): void => {};
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);

  const handleDeleteKey = (id: string) => {
    setIsProcessingRequest(true);
    developerApi
      .deleteKey(id)
      .then(() => {
        notify("success", "API key have been deleted!");
        onDelete && onDelete();
      })
      .finally(() => setIsProcessingRequest(false));
  };

  const showDeleteUserConfirmation = (id: string): void => {
    confirmAction({
      title: "Are you sure you want to delete key?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: { className: "cancel-button" },
      onOk() {
        handleDeleteKey(id);
      },
      onCancel() {},
    });
  };

  const getTableData = () => {
    return (
      keys?.map((key: any) => ({
        access_key: key.id + "...",
        created_by: key.created_by,
        created_at: moment(key.created_at).format("MMM Do, HH:mm"),
        actions: (
          <FormButton
            theme="red"
            onClick={() => {
              showDeleteUserConfirmation(key.id);
            }}
          >
            <FaRegTrashAlt />
          </FormButton>
        ),
      })) || []
    );
  };

  return (
    <div className={baseClassName}>
      <DataTable
        isLoading={isLoading || isProcessingRequest}
        columns={TABLE_COLUMNS}
        data={getTableData()}
        onPagination={handlePagination}
      />
    </div>
  );
};
