export const APP_ROUTES = {
  home: "/",
  installExtension: "/install-extension",
  auth: "/auth",
  settings: "/settings",
  specialOffers: "/special-offers",
  team: "/team",
  dashboard: "/dashboard",
  billing: "/billing",
  fieldsMapping: "/fields-mapping",
  toolsAndApps: "/tools-and-apps",
  aiLeadGenerator: "/ai-job-researcher",
  releaseNotes: "/release-notes",
  registration: "/sign-up",
  plans: "/plans",
  pipedrive: "/pipedrive/callback",
  login: "/login",
  updateBilling: "/profile/update-billing",
  choosePaymentMethod: "/profile/payment-methods",
  extensionSignOut: "/ex-sign-out",
  extensionAuth: "/extension-auth",
  paymentProcessing: "/payment-processing",
  roadmap: "/roadmap",
  featureRequest: "/feature-request",
  proposals: "/proposals",
  api: "/api",
  // extension routes
  extensionGeneral: "/extension/general",
  extensionJobScoreDetails: "/extension/job-score-details",
  extensionUserProfile: "/extension/user-profile",
  extensionAnalytics: "/extension/analytics",
  extensionDeals: "/extension/deals",
  extensionCRM: "/extension/crm",
  extensionPrompts: "/extension/prompts",
  extensionPromptCreate: "/extension/prompts/create",
  extensionPromptEdit: "/extension/prompts/edit",
  extensionSettings: "/extension/settings",
};
