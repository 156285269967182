import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { DataTable } from "../../../ui";
import { paginate } from "../../../ui/data-table/data-table-utils";
import { ColumnAlign } from "../../../data-access";
import "@patternfly/react-core/dist/styles/base.css";
import "./logs.scss";
import { researcherApi } from "../../../api/job-researcher";

interface ILogsProps {
  isLoading?: boolean;
}

const TABLE_COLUMNS = [
  { displayName: "Action", textAlign: ColumnAlign.LEFT },
  { displayName: "Date", textAlign: ColumnAlign.RIGHT },
];
const baseClassName = "logs";

export const Logs = ({ isLoading = false }: ILogsProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);

  const handlePagination = (perPage: number, requestedPage: number): void => {
    setPerPage(perPage);
    setCurrentPage(requestedPage);
  };

  useEffect(() => {
    setIsProcessingRequest(true);
    researcherApi
      .getJobResearcherTaskActivity("1", currentPage, perPage)
      .then((response) => {
        const {
          data: {
            activity_logs,
            pagination: { page, total_records },
          },
        } = response;
        setTotalLogs(total_records);
        setCurrentPage(page);
        setData(activity_logs);
      })
      .finally(() => setIsProcessingRequest(false));
  }, [currentPage, perPage]);

  useEffect(() => {
    let getIntervalId: ReturnType<typeof setInterval> | string = "";

    getIntervalId = setInterval(() => {
      researcherApi
        .getJobResearcherTaskActivity("1", currentPage, perPage)
        .then((response) => {
          const {
            data: {
              activity_logs,
              pagination: { page, total_records },
            },
          } = response;
          setTotalLogs(total_records);
          setCurrentPage(page);
          setData(activity_logs);
        });
    }, 30000);

    return () => {
      getIntervalId && clearInterval(getIntervalId);
    };
  }, [currentPage, perPage]);

  const getTableData = () => {
    return (
      data?.map((log: any) => ({
        action: log.action,
        date: moment(log.timestamp).format("MMM Do, HH:mm"),
      })) || []
    );
  };

  return (
    <div className={baseClassName}>
      <DataTable
        isLoading={isLoading || isProcessingRequest}
        columns={TABLE_COLUMNS}
        perPage={perPage}
        currentPage={currentPage}
        data={getTableData()}
        customPagination={true}
        isSpecificPagination={true}
        onPagination={handlePagination}
        totalRecords={totalLogs}
      />
    </div>
  );
};

Logs.propTypes = {
  isLoading: PropTypes.bool,
  taskId: PropTypes.string,
};
