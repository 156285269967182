export const appConfig = {
  version: "3.0.3",
  actualExtensionVersion: "3.0.3",
  extensionInstallLink:
    "https://chrome.google.com/webstore/detail/upwex/pmipgiahphnifpajbfnpahjfkanpfabf",
  extensionUpdateLink:
    "https://help.upwex.io/en/articles/how-to-update-the-extension/",
  priceForAdditional50Requests: 2.75,
  settings: {
    allowRedesign: false,
  },
  trialPeriodDuration: 7,
  scumFilterDomain: ["companydummymail", "companydummy"],
  roadmap: {
    isEnabled: process.env.REACT_APP_ENABLE_ROADMAP === "true",
  },
  featuresRequest: {
    allowedUsersEmails: [
      "serhii.the.grey@gmail.com",
      "roman@synapseteam.com",
      "serhii.k@synapseteam.com",
    ],
  },
  enableGTAG: process.env.REACT_APP_ENABLE_GTAG === "true",
  extensionDownloadLink:
    "https://chromewebstore.google.com/detail/upwex-ai-tools-for-upwork/pmipgiahphnifpajbfnpahjfkanpfabf",
};
