import React, { FocusEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IGetSettingsResponse, IResponseError } from "../../../data-access";
import { FormInput, FormButton, InformationBlock } from "../../../ui";
import { notify, handleFailedRequest } from "../../../util";
import { developerApi } from "../../../api/developer-tools";
import "./webhook-url.scss";

const baseClass = "webhook-url";

export const WebhookForm = (): JSX.Element => {
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);

  const form = useForm<{ url: string }>({
    mode: "all",
    defaultValues: {
      url: "",
    },
  });
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isValid },
  } = form;

  useEffect(() => {
    developerApi.getWebhookURL().then((response: any) => {
      const {
        data: { url },
      } = response;
      setValue("url", url, {
        shouldDirty: true,
        shouldValidate: false,
        shouldTouch: true,
      });
    });
  }, []);

  const handleFormSubmit = (formFields: { url: string }): void => {
    setIsProcessingForm(true);
    developerApi
      .createWebhookUrl(formFields.url)
      .then((response: IGetSettingsResponse) => {
        notify("success", "Webhook URL have been successfully updated.");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingForm(false));
  };

  const isFormBeenModified = Object.keys(dirtyFields).length > 0;

  return (
    <form className={`${baseClass}`} onSubmit={handleSubmit(handleFormSubmit)}>
      <div className={`${baseClass}__container`}>
        <div className={`${baseClass}__content`}>
          <InformationBlock>
            Enter the URL where you want to receive webhook events in real time.
            Make sure your server is ready to handle incoming requests, as this
            will allow your application to receive notifications for events like
            job openings, bids, replies, direct messages, and invites.
          </InformationBlock>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>URL</h2>
              <FormInput
                {...register("url", {
                  required: "This field is required",
                })}
                value={watch("url")}
                disabled={isProcessingForm}
                error={errors?.url?.message}
                placeholder="Example: https://webhook.site"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("url", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <FormButton
        className={`${baseClass}__button`}
        htmlType="submit"
        loading={isProcessingForm}
        disabled={!isFormBeenModified || !isValid || isProcessingForm}
      >
        Save
      </FormButton>
    </form>
  );
};

export default WebhookForm;
