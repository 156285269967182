export const clientLocations = [
  {
    title: "Regions",
    label: "Regions",
    options: [
      { label: "Africa", value: "Africa" },
      { label: "Americas", value: "Americas" },
      { label: "Antarctica", value: "Antarctica" },
      { label: "Asia", value: "Asia" },
      { label: "Europe", value: "Europe" },
      { label: "Oceania", value: "Oceania" },
    ],
  },
  {
    title: "Subregions",
    label: "Subregions",
    options: [
      {
        label: "Australia and New Zealand",
        value: "Australia and New Zealand",
      },
      { label: "Caribbean", value: "Caribbean" },
      { label: "Central America", value: "Central America" },
      { label: "Central Asia", value: "Central Asia" },
      { label: "Eastern Africa", value: "Eastern Africa" },
      { label: "Eastern Asia", value: "Eastern Asia" },
      { label: "Eastern Europe", value: "Eastern Europe" },
      { label: "Melanesia", value: "Melanesia" },
      { label: "Micronesia", value: "Micronesia" },
      { label: "Middle Africa", value: "Middle Africa" },
      { label: "Northern Africa", value: "Northern Africa" },
      { label: "Northern America", value: "Northern America" },
      { label: "Northern Europe", value: "Northern Europe" },
      { label: "Polynesia", value: "Polynesia" },
      { label: "South America", value: "South America" },
      { label: "South-Eastern Asia", value: "South-Eastern Asia" },
      { label: "Southern Africa", value: "Southern Africa" },
      { label: "Southern Asia", value: "Southern Asia" },
      { label: "Southern Europe", value: "Southern Europe" },
      { label: "Western Africa", value: "Western Africa" },
      { label: "Western Asia", value: "Western Asia" },
      { label: "Western Europe", value: "Western Europe" },
    ],
  },
  {
    title: "Countries",
    label: "Countries",
    options: [
      { label: "Albania", value: "Albania" },
      { label: "Algeria", value: "Algeria" },
      { label: "American Samoa", value: "American Samoa" },
      { label: "Andorra", value: "Andorra" },
      { label: "Angola", value: "Angola" },
      { label: "Anguilla", value: "Anguilla" },
      { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
      { label: "Argentina", value: "Argentina" },
      { label: "Armenia", value: "Armenia" },
      { label: "Aruba", value: "Aruba" },
      { label: "Australia", value: "Australia" },
      { label: "Austria", value: "Austria" },
      { label: "Azerbaijan", value: "Azerbaijan" },
      { label: "Bahamas", value: "Bahamas" },
      { label: "Bahrain", value: "Bahrain" },
      { label: "Bangladesh", value: "Bangladesh" },
      { label: "Barbados", value: "Barbados" },
      { label: "Belgium", value: "Belgium" },
      { label: "Belize", value: "Belize" },
      { label: "Benin", value: "Benin" },
      { label: "Bermuda", value: "Bermuda" },
      { label: "Bhutan", value: "Bhutan" },
      { label: "Bolivia", value: "Bolivia" },
      {
        label: "Bonaire, Sint Eustatius and Saba",
        value: "Bonaire, Sint Eustatius and Saba",
      },
      { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
      { label: "Botswana", value: "Botswana" },
      { label: "Bouvet Island", value: "Bouvet Island" },
      { label: "Brazil", value: "Brazil" },
      {
        label: "British Indian Ocean Territory",
        value: "British Indian Ocean Territory",
      },
      { label: "British Virgin Islands", value: "British Virgin Islands" },
      { label: "Brunei Darussalam", value: "Brunei Darussalam" },
      { label: "Bulgaria", value: "Bulgaria" },
      { label: "Burkina Faso", value: "Burkina Faso" },
      { label: "Burundi", value: "Burundi" },
      { label: "Cambodia", value: "Cambodia" },
      { label: "Cameroon", value: "Cameroon" },
      { label: "Canada", value: "Canada" },
      { label: "Cape Verde", value: "Cape Verde" },
      { label: "Cayman Islands", value: "Cayman Islands" },
      { label: "Central African Republic", value: "Central African Republic" },
      { label: "Chad", value: "Chad" },
      { label: "Chile", value: "Chile" },
      { label: "China", value: "China" },
      { label: "Christmas Island", value: "Christmas Island" },
      { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
      { label: "Colombia", value: "Colombia" },
      { label: "Comoros", value: "Comoros" },
      { label: "Congo", value: "Congo" },
      {
        label: "Congo, the Democratic Republic of the",
        value: "Congo, the Democratic Republic of the",
      },
      { label: "Cook Islands", value: "Cook Islands" },
      { label: "Costa Rica", value: "Costa Rica" },
      { label: "Cote d'Ivoire", value: "Cote d'Ivoire" },
      { label: "Croatia", value: "Croatia" },
      { label: "Curacao", value: "Curacao" },
      { label: "Cyprus", value: "Cyprus" },
      { label: "Czech Republic", value: "Czech Republic" },
      { label: "Denmark", value: "Denmark" },
      { label: "Djibouti", value: "Djibouti" },
      { label: "Dominica", value: "Dominica" },
      { label: "Dominican Republic", value: "Dominican Republic" },
      { label: "Ecuador", value: "Ecuador" },
      { label: "Egypt", value: "Egypt" },
      { label: "El Salvador", value: "El Salvador" },
      { label: "Equatorial Guinea", value: "Equatorial Guinea" },
      { label: "Eritrea", value: "Eritrea" },
      { label: "Estonia", value: "Estonia" },
      { label: "Ethiopia", value: "Ethiopia" },
      { label: "Falkland Islands", value: "Falkland Islands" },
      { label: "Faroe Islands", value: "Faroe Islands" },
      { label: "Fiji", value: "Fiji" },
      { label: "Finland", value: "Finland" },
      { label: "France", value: "France" },
      { label: "French Guiana", value: "French Guiana" },
      { label: "French Polynesia", value: "French Polynesia" },
      {
        label: "French Southern and Antarctic Lands",
        value: "French Southern and Antarctic Lands",
      },
      { label: "Gabon", value: "Gabon" },
      { label: "Gambia", value: "Gambia" },
      { label: "Georgia", value: "Georgia" },
      { label: "Germany", value: "Germany" },
      { label: "Ghana", value: "Ghana" },
      { label: "Gibraltar", value: "Gibraltar" },
      { label: "Greece", value: "Greece" },
      { label: "Greenland", value: "Greenland" },
      { label: "Grenada", value: "Grenada" },
      { label: "Guadeloupe", value: "Guadeloupe" },
      { label: "Guam", value: "Guam" },
      { label: "Guatemala", value: "Guatemala" },
      { label: "Guernsey", value: "Guernsey" },
      { label: "Guinea", value: "Guinea" },
      { label: "Guinea-Bissau", value: "Guinea-Bissau" },
      { label: "Guyana", value: "Guyana" },
      { label: "Haiti", value: "Haiti" },
      {
        label: "Heard Island and McDonald Islands",
        value: "Heard Island and McDonald Islands",
      },
      { label: "Holy See", value: "Holy See" },
      { label: "Honduras", value: "Honduras" },
      { label: "Hong Kong", value: "Hong Kong" },
      { label: "Hungary", value: "Hungary" },
      { label: "Iceland", value: "Iceland" },
      { label: "India", value: "India" },
      { label: "Indonesia", value: "Indonesia" },
      { label: "Ireland", value: "Ireland" },
      { label: "Isle of Man", value: "Isle of Man" },
      { label: "Israel", value: "Israel" },
      { label: "Italy", value: "Italy" },
      { label: "Jamaica", value: "Jamaica" },
      { label: "Japan", value: "Japan" },
      { label: "Jersey", value: "Jersey" },
      { label: "Jordan", value: "Jordan" },
      { label: "Kazakhstan", value: "Kazakhstan" },
      { label: "Kenya", value: "Kenya" },
      { label: "Kiribati", value: "Kiribati" },
      { label: "Kuwait", value: "Kuwait" },
      { label: "Kyrgyzstan", value: "Kyrgyzstan" },
      { label: "Laos", value: "Laos" },
      { label: "Latvia", value: "Latvia" },
      { label: "Lebanon", value: "Lebanon" },
      { label: "Lesotho", value: "Lesotho" },
      { label: "Liechtenstein", value: "Liechtenstein" },
      { label: "Lithuania", value: "Lithuania" },
      { label: "Luxembourg", value: "Luxembourg" },
      { label: "Macao", value: "Macao" },
      { label: "Macedonia", value: "Macedonia" },
      { label: "Madagascar", value: "Madagascar" },
      { label: "Malawi", value: "Malawi" },
      { label: "Malaysia", value: "Malaysia" },
      { label: "Maldives", value: "Maldives" },
      { label: "Mali", value: "Mali" },
      { label: "Malta", value: "Malta" },
      { label: "Marshall Islands", value: "Marshall Islands" },
      { label: "Martinique", value: "Martinique" },
      { label: "Mauritania", value: "Mauritania" },
      { label: "Mauritius", value: "Mauritius" },
      { label: "Mayotte", value: "Mayotte" },
      { label: "Mexico", value: "Mexico" },
      {
        label: "Micronesia, Federated States of",
        value: "Micronesia, Federated States of",
      },
      { label: "Moldova", value: "Moldova" },
      { label: "Monaco", value: "Monaco" },
      { label: "Mongolia", value: "Mongolia" },
      { label: "Montenegro", value: "Montenegro" },
      { label: "Montserrat", value: "Montserrat" },
      { label: "Morocco", value: "Morocco" },
      { label: "Mozambique", value: "Mozambique" },
      { label: "Myanmar", value: "Myanmar" },
      { label: "Namibia", value: "Namibia" },
      { label: "Nauru", value: "Nauru" },
      { label: "Nepal", value: "Nepal" },
      { label: "Netherlands", value: "Netherlands" },
      { label: "Netherlands Antilles", value: "Netherlands Antilles" },
      { label: "New Caledonia", value: "New Caledonia" },
      { label: "New Zealand", value: "New Zealand" },
      { label: "Nicaragua", value: "Nicaragua" },
      { label: "Niger", value: "Niger" },
      { label: "Nigeria", value: "Nigeria" },
      { label: "Niue", value: "Niue" },
      { label: "Norfolk Island", value: "Norfolk Island" },
      { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
      { label: "Norway", value: "Norway" },
      { label: "Oman", value: "Oman" },
      { label: "Pakistan", value: "Pakistan" },
      { label: "Palau", value: "Palau" },
      { label: "Palestinian Territories", value: "Palestinian Territories" },
      { label: "Panama", value: "Panama" },
      { label: "Papua New Guinea", value: "Papua New Guinea" },
      { label: "Paraguay", value: "Paraguay" },
      { label: "Peru", value: "Peru" },
      { label: "Philippines", value: "Philippines" },
      { label: "Pitcairn", value: "Pitcairn" },
      { label: "Poland", value: "Poland" },
      { label: "Portugal", value: "Portugal" },
      { label: "Puerto Rico", value: "Puerto Rico" },
      { label: "Qatar", value: "Qatar" },
      { label: "Reunion", value: "Reunion" },
      { label: "Romania", value: "Romania" },
      { label: "Rwanda", value: "Rwanda" },
      { label: "Saint Barthelemy", value: "Saint Barthelemy" },
      { label: "Saint Helena", value: "Saint Helena" },
      { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
      { label: "Saint Lucia", value: "Saint Lucia" },
      {
        label: "Saint Martin (French part)",
        value: "Saint Martin (French part)",
      },
      {
        label: "Saint Pierre and Miquelon",
        value: "Saint Pierre and Miquelon",
      },
      {
        label: "Saint Vincent and the Grenadines",
        value: "Saint Vincent and the Grenadines",
      },
      { label: "Samoa", value: "Samoa" },
      { label: "San Marino", value: "San Marino" },
      { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
      { label: "Saudi Arabia", value: "Saudi Arabia" },
      { label: "Senegal", value: "Senegal" },
      { label: "Serbia", value: "Serbia" },
      { label: "Seychelles", value: "Seychelles" },
      { label: "Sierra Leone", value: "Sierra Leone" },
      { label: "Singapore", value: "Singapore" },
      {
        label: "Sint Maarten (Dutch part)",
        value: "Sint Maarten (Dutch part)",
      },
      { label: "Slovakia", value: "Slovakia" },
      { label: "Slovenia", value: "Slovenia" },
      { label: "Solomon Islands", value: "Solomon Islands" },
      { label: "Somalia", value: "Somalia" },
      { label: "South Africa", value: "South Africa" },
      { label: "South Korea", value: "South Korea" },
      { label: "Spain", value: "Spain" },
      { label: "Sri Lanka", value: "Sri Lanka" },
      { label: "Suriname", value: "Suriname" },
      { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
      { label: "Swaziland", value: "Swaziland" },
      { label: "Sweden", value: "Sweden" },
      { label: "Switzerland", value: "Switzerland" },
      { label: "Taiwan", value: "Taiwan" },
      { label: "Tajikistan", value: "Tajikistan" },
      { label: "Tanzania", value: "Tanzania" },
      { label: "Thailand", value: "Thailand" },
      { label: "Timor-Leste", value: "Timor-Leste" },
      { label: "Togo", value: "Togo" },
      { label: "Tokelau", value: "Tokelau" },
      { label: "Tonga", value: "Tonga" },
      { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
      { label: "Tunisia", value: "Tunisia" },
      { label: "Turkey", value: "Turkey" },
      { label: "Turkmenistan", value: "Turkmenistan" },
      { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
      { label: "Tuvalu", value: "Tuvalu" },
      { label: "Uganda", value: "Uganda" },
      { label: "Ukraine", value: "Ukraine" },
      { label: "United Arab Emirates", value: "United Arab Emirates" },
      { label: "United Kingdom", value: "United Kingdom" },
      { label: "United States", value: "United States" },
      {
        label: "United States Minor Outlying Islands",
        value: "United States Minor Outlying Islands",
      },
      {
        label: "United States Virgin Islands",
        value: "United States Virgin Islands",
      },
      { label: "Uruguay", value: "Uruguay" },
      { label: "Uzbekistan", value: "Uzbekistan" },
      { label: "Vanuatu", value: "Vanuatu" },
      { label: "Venezuela", value: "Venezuela" },
      { label: "Vietnam", value: "Vietnam" },
      { label: "Wallis and Futuna", value: "Wallis and Futuna" },
      { label: "Western Sahara", value: "Western Sahara" },
      { label: "Yemen", value: "Yemen" },
      { label: "Zambia", value: "Zambia" },
      { label: "Zimbabwe", value: "Zimbabwe" },
    ],
  },
];

export const clientTimezones = [
  {
    label: "UTC-11:00 Midway Island, Samoa",
    value: "Pacific/Midway",
  },
  {
    label: "UTC-10:00 Hawaii",
    value: "Pacific/Honolulu",
  },
  {
    label: "UTC-09:00 Alaska",
    value: "America/Nome",
  },
  {
    label: "UTC-08:00 Pacific Time (US & Canada); Los Angeles",
    value: "America/Los_Angeles",
  },
  {
    label: "UTC-08:00 Pacific Time (US & Canada); Tijuana",
    value: "America/Tijuana",
  },
  {
    label: "UTC-07:00 Arizona",
    value: "America/Phoenix",
  },
  {
    label: "UTC-07:00 Mountain Time (US & Canada)",
    value: "America/Denver",
  },
  {
    label: "UTC-06:00 Saskatchewan",
    value: "America/Regina",
  },
  {
    label: "UTC-06:00 Eastern Time - Indiana - Starke County",
    value: "America/Indiana/Knox",
  },
  {
    label: "UTC-06:00 Nicaragua",
    value: "America/Managua",
  },
  {
    label: "UTC-06:00 Mexico City, Tegucigalpa",
    value: "America/Mexico_City",
  },
  {
    label: "UTC-06:00 Central Time (US & Canada)",
    value: "America/Chicago",
  },
  {
    label: "UTC-05:00 Eastern Time (US & Canada)",
    value: "America/New_York",
  },
  {
    label: "UTC-05:00 Bogota, Lima",
    value: "America/Bogota",
  },
  {
    label: "UTC-05:00 Eastern Time - Indiana - most locations",
    value: "America/Indiana/Indianapolis",
  },
  {
    label: "UTC-04:00 Atlantic Time (Canada)",
    value: "America/Halifax",
  },
  {
    label: "UTC-04:00 La Paz",
    value: "America/La_Paz",
  },
  {
    label: "UTC-04:00 Caracas",
    value: "America/Caracas",
  },
  {
    label: "UTC-03:00 NE Brazil (MA, PI, CE, RN, PB)",
    value: "America/Fortaleza",
  },
  {
    label: "UTC-03:00 E Argentina (BA, DF, SC, TF)",
    value: "America/Buenos_Aires",
  },
  {
    label: "UTC-03:30 Newfoundland",
    value: "America/St_Johns",
  },
  {
    label: "UTC-03:00 Pernambuco",
    value: "America/Recife",
  },
  {
    label: "UTC-03:00 S & SE Brazil (GO, DF, MG, ES, RJ, SP, PR, SC, RS)",
    value: "America/Sao_Paulo",
  },
  {
    label: "UTC-02:00 Mid-Atlantic",
    value: "Atlantic/South_Georgia",
  },
  {
    label: "UTC-01:00 Azores, Cape Verde Island",
    value: "Atlantic/Azores",
  },
  {
    label: "UTC (no DST) Tangiers, Casablanca",
    value: "Africa/Casablanca",
  },
  {
    label: "UTC (Coordinated Universal Time) Dublin, Edinburgh, London",
    value: "Europe/London",
  },
  {
    label: "UTC+00:00 Lisbon",
    value: "Europe/Lisbon",
  },
  {
    label: "UTC (Coordinated Universal Time)",
    value: "Etc/UTC",
  },
  {
    label: "UTC+01:00 Prague, Warsaw",
    value: "Europe/Prague",
  },
  {
    label: "UTC+01:00 Paris, Madrid",
    value: "Europe/Paris",
  },
  {
    label: "UTC+01:00 Berlin, Stockholm, Rome, Bern, Brussels",
    value: "Europe/Berlin",
  },
  {
    label: "UTC+01:00 Algeria",
    value: "Africa/Algiers",
  },
  {
    label: "UTC+02:00 Athens, Helsinki, Istanbul",
    value: "Europe/Athens",
  },
  {
    label: "UTC+02:00 Eastern Europe",
    value: "EET",
  },
  {
    label: "UTC+02:00 Cairo",
    value: "Africa/Cairo",
  },
  {
    label: "UTC+02:00 Harare, Pretoria",
    value: "Africa/Harare",
  },
  {
    label: "UTC+02:00 Israel",
    value: "Asia/Jerusalem",
  },
  {
    label: "UTC+03:00 Minsk",
    value: "Europe/Minsk",
  },
  {
    label: "UTC+03:00 Moscow, St. Petersburg, Volgograd",
    value: "Europe/Moscow",
  },
  {
    label: "UTC+03:30 Tehran",
    value: "Asia/Tehran",
  },
  {
    label: "UTC+03:00 Baghdad, Kuwait, Nairobi, Riyadh",
    value: "Asia/Baghdad",
  },
  {
    label: "UTC+04:00 Abu Dhabi, Muscat, Tbilisi, Kazan",
    value: "Asia/Tbilisi",
  },
  {
    label: "UTC+05:00 Islamabad, Karachi",
    value: "Asia/Karachi",
  },
  {
    label: "UTC+04:30 Kabul",
    value: "Asia/Kabul",
  },
  {
    label: "UTC+04:00 Armenia",
    value: "Asia/Yerevan",
  },
  {
    label: "UTC+05:45 Kathmandu, Nepal",
    value: "Asia/Katmandu",
  },
  {
    label: "UTC+05:00 Tashkent",
    value: "Asia/Tashkent",
  },
  {
    label: "UTC+05:00 Sverdlovsk",
    value: "Asia/Yekaterinburg",
  },
  {
    label: "UTC+06:00 Almaty, Dhaka",
    value: "Asia/Almaty",
  },
  {
    label: "UTC+05:30 Mumbai, Kolkata, Chennai, New Delhi",
    value: "Asia/Calcutta",
  },
  {
    label: "UTC+06:00 Omsk, Novosibirsk",
    value: "Asia/Omsk",
  },
  {
    label: "UTC+07:00 Krasnoyarsk",
    value: "Asia/Krasnoyarsk",
  },
  {
    label: "UTC+07:00 Bangkok, Jakarta, Hanoi",
    value: "Asia/Bangkok",
  },
  {
    label: "UTC+08:00 Hong Kong SAR, Perth, Singapore, Taipei",
    value: "Australia/Perth",
  },
  {
    label: "UTC+08:00 Irkutsk (Lake Baikal)",
    value: "Asia/Irkutsk",
  },
  {
    label: "UTC+08:00 Beijing, Chongqing, Urumqi",
    value: "Asia/Shanghai",
  },
  {
    label: "UTC+09:00 Tokyo, Osaka, Sapporo, Seoul",
    value: "Asia/Tokyo",
  },
  {
    label: "UTC+10:00 Guam, Port Moresby",
    value: "Pacific/Guam",
  },
  {
    label: "UTC+10:00 Magadan, Vladivostok",
    value: "Asia/Vladivostok",
  },
  {
    label: "UTC+11:00 Hobart",
    value: "Australia/Hobart",
  },
  {
    label: "UTC+09:30 Darwin",
    value: "Australia/Darwin",
  },
  {
    label: "UTC+10:00 Sydney, Melbourne",
    value: "Australia/Sydney",
  },
  {
    label: "UTC+10:00 Brisbane",
    value: "Australia/Brisbane",
  },
  {
    label: "UTC+09:30 Adelaide",
    value: "Australia/Adelaide",
  },
  {
    label: "UTC+10:00 Yakutsk (Lena River)",
    value: "Asia/Yakutsk",
  },
  {
    label: "UTC+12:00 Fiji Islands, Marshall Islands",
    value: "Pacific/Fiji",
  },
  {
    label: "UTC+12:00 Wellington, Auckland",
    value: "Pacific/Auckland",
  },
  {
    label: "UTC+12:00 Eniwetok, Kwajalein",
    value: "Pacific/Kwajalein",
  },
  {
    label: "UTC+12:00 Kamchatka",
    value: "Asia/Kamchatka",
  },
  {
    label: "UTC+12:00 Solomon Islands, New Caledonia",
    value: "Asia/Magadan",
  },
  {
    label: "UTC+13:00 Apia (Samoa)",
    value: "Pacific/Apia",
  },
];

export const upworkCategories = [
  {
    label: "Accounting & Consulting",
    title: "Accounting & Consulting",
    options: [
      {
        label: "Accounting & Bookkeeping",
        value: "531770282601639943",
      },
      {
        label: "Financial Planning",
        value: "531770282601639945",
      },
      {
        label: "Management Consulting & Analysis",
        value: "531770282601639944",
      },
      {
        label: "Other - Accounting & Consulting",
        value: "531770282601639947",
      },
      {
        label: "Personal & Professional Coaching",
        value: "1534904461833879552",
      },
      {
        label: "Recruiting & Human Resources",
        value: "531770282601639946",
      },
    ],
  },
  {
    label: "Admin Support",
    title: "Admin Support",
    options: [
      {
        label: "Data Entry & Transcription Services",
        value: "531770282584862724",
      },
      {
        label: "Market Research & Product Reviews",
        value: "531770282584862726",
      },
      {
        label: "Project Management",
        value: "531770282584862728",
      },
      {
        label: "Virtual Assistance",
        value: "531770282584862725",
      },
    ],
  },
  {
    label: "Customer Service",
    title: "Customer Service",
    options: [
      {
        label: "Community Management & Tagging",
        value: "1484275072572772352",
      },
      {
        label: "Customer Service & Tech Support",
        value: "531770282584862730",
      },
    ],
  },
  {
    label: "Data Science & Analytics",
    title: "Data Science & Analytics",
    options: [
      {
        label: "AI & Machine Learning",
        value: "531770282593251329",
      },
      {
        label: "Data Analysis & Testing",
        value: "531770282593251330",
      },
      {
        label: "Data Extraction/ETL",
        value: "531770282593251331",
      },
      {
        label: "Data Mining & Management",
        value: "531770282589057038",
      },
    ],
  },
  {
    label: "Design & Creative",
    title: "Design & Creative",
    options: [
      {
        label: "Art & Illustration",
        value: "531770282593251335",
      },
      {
        label: "Audio & Music Production",
        value: "531770282593251341",
      },
      {
        label: "Branding & Logo Design",
        value: "1044578476142100480",
      },
      {
        label: "Graphic, Editorial & Presentation Design",
        value: "531770282593251334",
      },
      {
        label: "NFT, AR/VR & Game Art",
        value: "1356688560628174848",
      },
      {
        label: "Performing Arts",
        value: "1356688565288046592",
      },
      {
        label: "Photography",
        value: "531770282593251340",
      },
      {
        label: "Product Design",
        value: "531770282601639953",
      },
      {
        label: "Video & Animation",
        value: "1356688570056970240",
      },
    ],
  },
  {
    label: "Engineering & Architecture",
    options: [
      {
        label: "3D Modeling & CAD",
        value: "531770282601639948",
      },
      {
        label: "Building & Landscape Architecture",
        value: "531770282601639949",
      },
      {
        label: "Chemical Engineering",
        value: "531770282605834240",
      },
      {
        label: "Civil & Structural Engineering",
        value: "531770282601639950",
      },
      {
        label: "Contract Manufacturing",
        value: "531770282605834241",
      },
      {
        label: "Electrical & Electronic Engineering",
        value: "531770282601639951",
      },
      {
        label: "Energy & Mechanical Engineering",
        value: "531770282601639952",
      },
      {
        label: "Interior & Trade Show Design",
        value: "531770282605834242",
      },
      {
        label: "Physical Sciences",
        value: "1301900647896092672",
      },
    ],
  },
  {
    label: "IT & Networking",
    options: [
      {
        label: "Database Management & Administration",
        value: "531770282589057033",
      },
      {
        label: "DevOps & Solution Architecture",
        value: "531770282589057037",
      },
      {
        label: "ERP/CRM Software",
        value: "531770282589057034",
      },
      {
        label: "Information Security & Compliance",
        value: "531770282589057036",
      },
      {
        label: "Network & System Administration",
        value: "531770282589057035",
      },
    ],
  },
  {
    label: "Legal",
    options: [
      {
        label: "Corporate & Contract Law",
        value: "531770282605834246",
      },
      {
        label: "Finance & Tax Law",
        value: "531770283696353280",
      },
      {
        label: "International & Immigration Law",
        value: "1484275156546932736",
      },
      {
        label: "Public Law",
        value: "1484275408410693632",
      },
    ],
  },
  {
    label: "Sales & Marketing",
    options: [
      {
        label: "Digital Marketing",
        value: "531770282597445636",
      },
      {
        label: "Lead Generation & Telemarketing",
        value: "531770282597445634",
      },
      {
        label: "Marketing, PR & Brand Strategy",
        value: "531770282593251343",
      },
    ],
  },
  {
    label: "Translation",
    options: [
      {
        label: "Language Tutoring & Interpretation",
        value: "1534904461842268160",
      },
      {
        label: "Translation & Localization Services",
        value: "531770282601639939",
      },
    ],
  },
  {
    label: "Web, Mobile & Software Dev",
    options: [
      {
        label: "AI Apps & Integration",
        value: "1737190722360750082",
      },
      {
        label: "Blockchain, NFT & Cryptocurrency",
        value: "1517518458442309632",
      },
      {
        label: "Desktop Application Development",
        value: "531770282589057025",
      },
      {
        label: "Ecommerce Development",
        value: "531770282589057026",
      },
      {
        label: "Game Design & Development",
        value: "531770282589057027",
      },
      {
        label: "Mobile Development",
        value: "531770282589057024",
      },
      {
        label: "Other - Software Development",
        value: "531770282589057032",
      },
      {
        label: "Product Management & Scrum",
        value: "531770282589057030",
      },
      {
        label: "QA Testing",
        value: "531770282589057031",
      },
      {
        label: "Scripts & Utilities",
        value: "531770282589057028",
      },
      {
        label: "Web & Mobile Design",
        value: "531770282589057029",
      },
      {
        label: "Web Development",
        value: "531770282584862733",
      },
    ],
  },
  {
    label: "Writing",
    options: [
      {
        label: "Content Writing",
        value: "1301900640421842944",
      },
      {
        label: "Editing & Proofreading Services",
        value: "531770282597445644",
      },
      {
        label: "Professional & Business Writing",
        value: "531770282597445646",
      },
      {
        label: "Sales & Marketing Copywriting",
        value: "1534904462131675136",
      },
    ],
  },
];

export const experienceLevelOptions = [
  { label: "Entry Level", value: "1" },
  { label: "Intermediate", value: "2" },
  { label: "Expert", value: "3" },
];

export const proposalsOptions = [
  { label: "Less than 5", value: "0-4" },
  { label: "5 to 10", value: "5-9" },
  { label: "10 to 15", value: "10-14" },
  { label: "15 to 20", value: "15-19" },
  { label: "20 to 50", value: "20-49" },
];

export const clientHistoryOptions = [
  { label: "No hires", value: "0" },
  { label: "1 to 9 hires", value: "1-9" },
  { label: "10+ hires", value: "10-" },
];

export const projectLengthOptions = [
  { label: "Less than one month", value: "weeks" },
  { label: "1 to 3 month", value: "months" },
  { label: "3 to 6 months", value: "semester" },
  { label: "More than 6 months", value: "ongoing" },
];

export const hoursPerWeekOptions = [
  { label: "Less than 30 hrs/week", value: "as_needed" },
  { label: "More than 30 hrs/week", value: "full_time" },
];

export const fixedPriceOptions = [
  { label: "Less than $100", value: "0-99" },
  { label: "$100 to $500", value: "100-499" },
  { label: "$500 - $1K", value: "500-999" },
  { label: "$1K - $5K", value: "1000-4999" },
  { label: "$5K+", value: "5000-" },
];

export const transformUrlToData = (url: string) => {
  let parsedData = {
    subcategory2_uid: [],
    contractor_tier: [],
    amount: [],
    proposals: [],
    client_hires: [],
    location: [],
    timezone: [],
    duration_v3: [],
    workload: [],
    min: null,
    max: null,
    previous_clients: false,
    payment_verified: false,
    contract_to_hire: false,
    q: "",
  };

  const urlParams = new URLSearchParams(url);
  const entries = urlParams.entries() as any;

  for (const entry of entries) {
    switch (entry[0]) {
      case "subcategory2_uid":
      case "contractor_tier":
      case "client_hires":
      case "location":
      case "timezone":
      case "duration_v3":
      case "workload":
      case "amount":
      case "proposals":
        // @ts-ignore
        parsedData[entry[0]] = entry[1] ? entry[1].split(",") : [];
        break;
      case "payment_verified":
      case "previous_clients":
      case "contract_to_hire":
        // @ts-ignore
        parsedData[entry[0]] = entry[1] ? true : false;
        break;
      case "q":
        // @ts-ignore
        parsedData[entry[0]] = entry[1];
        break;
      case "hourly_rate":
        const rate = entry[1] ? entry[1].split("-") : [];
        parsedData = {
          ...parsedData,
          // @ts-ignore
          min: +rate[0] || null,
          // @ts-ignore
          max: +rate[1] || null,
        };
        break;
      default:
        break;
    }
  }

  return parsedData;
};

export const transformDataToSearchString = (filters: any): string => {
  let urlParams = "";

  Object.keys(filters).forEach((key) => {
    switch (key) {
      case "subcategory2_uid":
      case "contractor_tier":
      case "client_hires":
      case "location":
      case "timezone":
      case "duration_v3":
      case "workload":
      case "amount":
      case "proposals":
        urlParams = filters[key].length
          ? `${urlParams}&${key}=${filters[key].join(",")}`
          : urlParams;
        break;
      case "payment_verified":
        urlParams = filters[key] ? `${urlParams}&${key}=1` : urlParams;
        break;
      case "previous_clients":
        urlParams = filters[key] ? `${urlParams}&${key}=all` : urlParams;
        break;
      case "contract_to_hire":
        urlParams = filters[key] ? `${urlParams}&${key}=true` : urlParams;
        break;
      case "q":
        urlParams = filters[key]
          ? `${urlParams}&${key}=${encodeURIComponent(filters[key])}`
          : urlParams;
        break;
      default:
        break;
    }
  });

  if (filters.min || filters.max) {
    urlParams = `${urlParams}&hourly_rate=${filters.min || ""}-${
      filters.max || ""
    }`;
  }

  return urlParams;
};
