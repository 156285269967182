import { axiosApiInstance } from "./axios";

export const baseApiPath = process.env.REACT_APP_API_ENDPOINT;

export const get = async <T>(url: string): Promise<T> => {
  return await axiosApiInstance
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject({
        status: error?.response?.status,
        reason: error?.response?.data?.error,
        text: error?.response?.data?.message,
        ...(error?.response?.data?.errors && {
          details: error?.response?.data?.errors,
        }),
      });
    });
};

export const post = async <T, TT>(url: string, data: T): Promise<TT> => {
  return await axiosApiInstance
    .post(url, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject({
        status: error?.response?.status,
        text: error?.response?.data?.message,
        ...(error?.response?.data?.errors && {
          details: error?.response?.data?.errors,
        }),
      });
    });
};

export const patch = async <T, TT>(url: string, data: T): Promise<TT> => {
  return await axiosApiInstance
    .patch(url, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject({
        status: error?.response?.status,
        text: error?.response?.data?.message,
        ...(error?.response?.data?.errors && {
          details: error?.response?.data?.errors,
        }),
      });
    });
};

export const put = async <T, TT>(url: string, data: T): Promise<TT> => {
  return await axiosApiInstance
    .put(url, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject({
        status: error?.response?.status,
        text: error?.response?.data?.message,
        ...(error?.response?.data?.errors && {
          details: error?.response?.data?.errors,
        }),
      });
    });
};

export const deleteEntity = async <TT>(url: string): Promise<TT> => {
  return await axiosApiInstance
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject({
        status: error?.response?.status,
        text: error?.response?.data?.message,
        ...(error?.response?.data?.errors && {
          details: error?.response?.data?.errors,
        }),
      });
    });
};
