import { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBan, FaInfoCircle } from "react-icons/fa";
import { Tag, Dropdown, Modal, Popover, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { billingApi, usersApi } from "../../api";
import {
  IResponseError,
  ITeamMemberRecord,
  ITeamMembersResponse,
  UserStatus,
  UserRoleColor,
  APP_ROUTES,
  ILicence,
  IUser,
  ModalType,
} from "../../data-access";
import { useUser, useMobileDevice } from "../../hooks";
import { storeAuthActions } from "../../store/slices/auth";
import { storeBillingActions } from "../../store/slices/billing";
import { storeTeamActions } from "../../store/slices/team";
import { storeModalsActions } from "../../store/slices/modals";
import { Avatar, FormButton, DataTable, Panel } from "../../ui";
import { paginate } from "../../ui/data-table/data-table-utils";
import {
  capitalize,
  notify,
  handleFailedRequest,
  updateExtensionCookies,
} from "../../util";
import "./team.scss";

export const Team = (): JSX.Element => {
  const switchableRoles = ["admin", "member"];

  const { currentUser, hasAdminRights } = useUser();
  const isMobileResolution = useMobileDevice();

  const [isInviteInProgress, setIsInviteInProgress] = useState<boolean>(false);
  const [isProcessingTeamMembers, setIsProcessingTeamMembers] =
    useState<boolean>(false);
  const [teamMembers, setTeamMembers] = useState<ITeamMemberRecord[]>(
    useSelector(
      (state: { team: { members: ITeamMemberRecord[] } }) => state.team.members
    )
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const dispatch = useDispatch();

  const { confirm: confirmAction } = Modal;

  // first - owner, second - current user, then all the rest
  const reorderTeamMembers = useCallback(
    (membersList: ITeamMemberRecord[]): ITeamMemberRecord[] => {
      const reorderedList = [...membersList];

      if (membersList.length > 1) {
        const currentUserIndex = reorderedList.findIndex(
          (member: ITeamMemberRecord) => member.id === currentUser?.id
        );
        const currentUserRecordInArray = reorderedList.splice(
          currentUserIndex,
          1
        );
        reorderedList.unshift(currentUserRecordInArray[0]);

        const ownerIndex = reorderedList.findIndex(
          (member: ITeamMemberRecord) => member.role === "owner"
        );
        const ownerRecordInArray = reorderedList.splice(ownerIndex, 1);
        reorderedList.unshift(ownerRecordInArray[0]);
      }

      return reorderedList;
    },
    [currentUser?.id]
  );

  // Need to fetch actual current user data to update cookie after purchasing on additional requests
  const requestCurrentUser = (): void => {
    usersApi.getMe().then((response: { data: IUser }) => {
      const userData = { user: response.data };
      dispatch(storeAuthActions.setUserData(userData));
      updateExtensionCookies(userData);
    });
  };

  const requestTeamMembers = (): void => {
    setIsProcessingTeamMembers(true);
    usersApi
      .getTeamMembers()
      .then((response: ITeamMembersResponse) => {
        const properlyOrderedMembersList = reorderTeamMembers(response.data);
        setTeamMembers(properlyOrderedMembersList);
        dispatch(storeTeamActions.setMembers(properlyOrderedMembersList));
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingTeamMembers(false));
  };

  const showDeleteUserConfirmation = (id: string): void => {
    confirmAction({
      title: "Are you sure you want to delete this user?",
      okText: "I'm sure",
      cancelText: "No",
      okButtonProps: { className: "cancel-button" },
      onOk() {
        handleDeleteUserClick(id);
      },
      onCancel() {},
    });
  };

  const updateTeamMember = (updatedMember: ITeamMemberRecord): void => {
    const targetUserIndex = teamMembers.findIndex(
      (member: ITeamMemberRecord) => member.id === updatedMember.id
    );
    const members = [...teamMembers];
    members[targetUserIndex] = updatedMember;
    dispatch(storeTeamActions.setMembers(members));
    setTeamMembers(members);
  };

  const resendInvitation = (email: string): void => {
    setIsInviteInProgress(true);
    usersApi
      .resendInvitation(email)
      .then(() => {
        notify("success", "Invitation has been successfully sent");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsInviteInProgress(false));
  };

  const handleDeleteUserClick = (id: string): void => {
    setIsProcessingTeamMembers(true);
    usersApi
      .deleteUser(id)
      .then(() => {
        const modifiedTeamMembers = teamMembers.filter(
          (member: ITeamMemberRecord) => member.id !== id
        );
        dispatch(storeTeamActions.setMembers(modifiedTeamMembers));
        setTeamMembers(modifiedTeamMembers);
        notify("success", "User has been successfully deleted");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingTeamMembers(false));
  };

  const handleChangeRole = (id: string, role: UserStatus): void => {
    setIsProcessingTeamMembers(true);
    usersApi
      .changeRole(id, role)
      .then((response) => {
        updateTeamMember(response.data);
        notify("success", `Role has been successfully changed to ${role}`);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingTeamMembers(false));
  };

  const getTeamTableActions = (user: ITeamMemberRecord) => [
    ...(!user.is_verified && user.role !== "owner"
      ? [
          {
            key: "resendInvitation",
            label: "Resend Invitation",
            onClick: () => resendInvitation(user.email),
          },
        ]
      : []),
    ...(user.id !== currentUser?.id && switchableRoles.includes(user.role)
      ? [
          {
            key: "changeRole",
            label: `Make ${user.role === "admin" ? "Member" : "Admin"}`,
            onClick: () =>
              handleChangeRole(
                user.id,
                user.role === "admin" ? "member" : "admin"
              ),
          },
        ]
      : []),
    ...(user.id !== currentUser?.id && user.role !== "owner"
      ? [
          {
            key: "delete",
            label: "Delete",
            onClick: () => showDeleteUserConfirmation(user.id),
          },
        ]
      : []),
  ];

  const showBuyExtraRequestsModal = (licenseId: string): void => {
    dispatch(
      storeModalsActions.open({
        type: ModalType.BuyExtraRequests,
        props: { licenseId },
      })
    );
  };

  const fetchLicenses = (): void => {
    setIsProcessingTeamMembers(true);
    billingApi
      .getLicences()
      .then(
        (response: {
          data: {
            billingLicenses: ILicence[];
            billingBotLicenses: ILicence[];
          };
        }) => {
          const billingLicenses = response.data.billingLicenses;
          const billingBotLicenses = response.data.billingBotLicenses;

          dispatch(
            storeBillingActions.update({
              licences: [...billingLicenses, ...billingBotLicenses],
            })
          );
        }
      )
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessingTeamMembers(false));
  };

  const TABLE_COLUMNS = [
    { displayName: "User" },
    { displayName: "Email" },
    { displayName: "Licences" },
    { displayName: "Available AI Requests" },
    { displayName: "Account Status" },
    { displayName: "Role" },
    { displayName: "Action" },
  ];

  const getStatusDisplay = (member: ITeamMemberRecord): JSX.Element => {
    return (
      <Tag color={member?.paid_user ? "green" : "red"}>
        {member?.paid_user ? "Paid" : "Not Paid"}
      </Tag>
    );
  };

  const getAvailableAiDisplay = (
    teamMember: ITeamMemberRecord
  ): JSX.Element => {
    const spentRequests = teamMember.license?.used_ai_requests || 0;
    const givenRequests = teamMember.license?.given_ai_requests || 500;

    return teamMember.license ? (
      <div className="available-requests">
        <Progress
          percent={
            spentRequests > 0
              ? Math.ceil((spentRequests / givenRequests) * 100)
              : 0
          }
          size="small"
          showInfo={false}
          strokeColor={spentRequests < givenRequests ? "green" : "red"}
        />
        <span className="available-requests__value">
          <span className="available-requests__value--spent">
            {spentRequests}
          </span>
          /
          <span className="available-requests__value--total">
            {givenRequests}
          </span>
        </span>
        {hasAdminRights && (
          <FormButton
            className="available-requests__button"
            onClick={() => showBuyExtraRequestsModal(teamMember.license!.id)}
          >
            +
          </FormButton>
        )}
      </div>
    ) : (
      <span>No assigned licenses</span>
    );
  };

  const getActionsDisplay = (
    teamMember: ITeamMemberRecord
  ): JSX.Element | null => {
    if (hasAdminRights) {
      const availableActions = getTeamTableActions(teamMember);
      return availableActions.length > 0 ? (
        !isMobileResolution ? (
          <Dropdown menu={{ items: availableActions }}>
            <span>...</span>
          </Dropdown>
        ) : (
          <div className="mobile-actions">
            {availableActions.map((action) => (
              <label onClick={action.onClick}>{action.label}</label>
            ))}
          </div>
        )
      ) : (
        <FaBan />
      );
    }

    return null;
  };

  const getTableData = () => {
    return teamMembers.map((teamMember) => ({
      full_name: (
        <div className="avatar-wrap">
          <Avatar imageSrc={teamMember?.picture ?? ""} />
          <span className="user-name">
            {teamMember.full_name ??
              teamMember.email.substring(0, teamMember.email.indexOf("@"))}
          </span>
        </div>
      ),
      email: teamMember.email,
      licences: getStatusDisplay(teamMember),
      available_requests: getAvailableAiDisplay(teamMember),
      is_verified: (
        <Tag color={teamMember.is_verified ? "green" : "orange"}>
          {capitalize(teamMember.is_verified ? "Active" : "Pending")}
        </Tag>
      ),
      role: (
        <Tag color={UserRoleColor[teamMember.role]} key={teamMember.role}>
          {capitalize(teamMember.role)}
        </Tag>
      ),
      action: getActionsDisplay(teamMember),
    }));
  };

  const handlePagination = (perPage: number, requestedPage: number): void => {
    const paginateData = paginate(teamMembers, perPage, requestedPage);
    const actualPage =
      paginateData.length > 0
        ? requestedPage
        : Math.ceil(teamMembers.length / perPage);
    setPerPage(perPage);
    setCurrentPage(actualPage);
  };

  const showInvitationModal = (): void => {
    dispatch(
      storeModalsActions.open({
        type: ModalType.InviteTeamMembers,
        props: { onSuccess: requestTeamMembers },
      })
    );
  };

  useEffect(() => {
    if (hasAdminRights) {
      fetchLicenses();
    }
    requestCurrentUser();
    requestTeamMembers();
  }, []);

  const inviteButtonComponent = hasAdminRights ? (
    <FormButton className="invite-button" onClick={showInvitationModal}>
      Invite +
    </FormButton>
  ) : null;

  const getLicenseColumnPopover = (): JSX.Element => {
    return (
      <span>
        To assign a licence go to the{" "}
        <Link className="popover-link" to={APP_ROUTES.billing}>
          Billing
        </Link>{" "}
        page
      </span>
    );
  };

  return (
    <Panel
      title="Team"
      className="team-panel"
      nodeInTitleRow={inviteButtonComponent}
    >
      <div className="team">
        <span className="available-licences">
          {hasAdminRights && (
            <>
              Available licences:{" "}
              <strong>{currentUser!.active_licenses_count}</strong>
              <NavLink to={APP_ROUTES.billing}>(see all licences)</NavLink>
            </>
          )}
        </span>
        <DataTable
          isLoading={isProcessingTeamMembers || isInviteInProgress}
          columns={TABLE_COLUMNS}
          perPage={perPage}
          currentPage={currentPage}
          data={getTableData()}
          tooltipToColumn={{
            2: (
              <Popover
                className="popover-icon"
                placement="topLeft"
                content={getLicenseColumnPopover()}
                trigger="hover"
              >
                <FaInfoCircle />
              </Popover>
            ),
          }}
          onPagination={handlePagination}
        />
      </div>
    </Panel>
  );
};

export default Team;
