import PropTypes from "prop-types";
import { FormButton, Spinner } from "../../../../ui";
import "./plan-card.scss";
import { IPlan } from "../../../../data-access";

interface IPlanCardProps {
  title: string;
  value: string | number | JSX.Element;
  buttonText?: string;
  onClick?: () => void;
  icon: JSX.Element;
  isLoading?: boolean;
  plan?: IPlan | null;
}

const baseClassName = "plan-card";

export const PlanCard = ({
  icon,
  title,
  value,
  buttonText,
  onClick,
  isLoading = false,
  plan = null,
}: IPlanCardProps): JSX.Element => {
  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        {title} {icon}
      </div>
      <div className={`${baseClassName}__body`}>
        <span className={`${baseClassName}__value`}>
          {isLoading ? <Spinner /> : value}
        </span>
        {plan && plan.is_selected && plan.is_archived ? (
          <FormButton disabled={true}>Switch Plan</FormButton>
        ) : buttonText ? (
          <FormButton onClick={onClick}>{buttonText}</FormButton>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
