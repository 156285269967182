import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import { FaBan } from "react-icons/fa";
import { billingApi } from "../../../api";
import {
  IPaymentHistoryRecord,
  IResponseError,
  LicenceColor,
} from "../../../data-access";
import { DataTable, FormButton, Panel } from "../../../ui";
import { capitalize, handleFailedRequest } from "../../../util";
import { paginate } from "../../../ui/data-table/data-table-utils";
import "./payment-history.scss";
import moment from "moment";

export const PaymentHistory = (): JSX.Element => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [paymentHistory, setPaymentHistory] = useState<IPaymentHistoryRecord[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const TABLE_COLUMNS = [
    { displayName: "Date" },
    { displayName: "Plan" },
    { displayName: "Licence" },
    { displayName: "Status" },
    { displayName: "Card" },
    { displayName: "Amount" },
    { displayName: "Action" },
  ];

  const openPaymentDetails = (url: string) => {
    window.open(url, "_blank");
  };

  const getTableData = (data: IPaymentHistoryRecord[]) => {
    return data.map((payment) => ({
      order_time: payment.order_time
        ? moment(payment.order_time).format("DD MMMM YYYY")
        : "-",
      billing_plan_name: payment.billing_plan_name,
      billing_license_id: payment.billing_license_id,
      status: (
        <Tag color={LicenceColor[payment.status as keyof typeof LicenceColor]}>
          {capitalize(payment.status.replace("_", " "))}
        </Tag>
      ),
      masked_card: payment.masked_card || <FaBan />,
      amount: payment.amount ? (
        `${(+payment.amount / 100).toFixed(2)} $`
      ) : (
        <FaBan />
      ),
      details: payment.stripe_details_url ? (
        <div className="button-wrap">
          <FormButton
            theme="green"
            onClick={() => openPaymentDetails(payment.stripe_details_url)}
          >
            Invoice
          </FormButton>
        </div>
      ) : (
        <FaBan />
      ),
    }));
  };

  const handlePagination = (perPage: number, requestedPage: number): void => {
    const paginateData = paginate(paymentHistory, perPage, requestedPage);
    const actualPage =
      paginateData.length > 0
        ? requestedPage
        : Math.ceil(paymentHistory.length / perPage);
    setPerPage(perPage);
    setCurrentPage(actualPage);
  };

  useEffect(() => {
    billingApi
      .getPaymentsHistory()
      .then(
        (response: {
          data: {
            billingPayments: IPaymentHistoryRecord[];
            billingBotPayments: IPaymentHistoryRecord[];
          };
        }) => {
          const paymentsData = [
            ...response.data.billingPayments,
            ...response.data.billingBotPayments,
          ];
          const sortedData = paymentsData.sort(
            (a: IPaymentHistoryRecord, b: IPaymentHistoryRecord) =>
              moment(b.order_time).unix() - moment(a.order_time).unix()
          );
          setPaymentHistory(sortedData);
        }
      )
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  return (
    <Panel title="Payment History" className="payment-history">
      <DataTable
        isLoading={isFetching}
        columns={TABLE_COLUMNS}
        perPage={perPage}
        currentPage={currentPage}
        data={getTableData(paymentHistory)}
        onPagination={handlePagination}
      />
    </Panel>
  );
};
