import PropTypes from "prop-types";
import { ModalType } from "../../data-access";
import { PurchaseLicencesModal } from "../billing/purchase-licences-modal";
import { DiscountModal } from "../billing/discount-modal";
import { InviteTeamMembersModal } from "../team/invite-team-members-modal";
import { BuyExtraRequestsModal } from "../team/buy-extra-requests-modal";
import {
  SubscriptionCancellationModal,
  SubscriptionCheckStatusModal,
} from "../billing";
import { WelcomeModal } from "../welcome/welcom-modal";
import { UpwexApiKeyModal } from "../upwex-api/upwex-api-key-modal";

interface IModalsFactoryProps {
  type: ModalType | null;
  onClose: () => void;
  [key: string]: unknown;
}

export const ModalsFactory = ({
  type,
  onClose,
  ...rest
}: IModalsFactoryProps): JSX.Element | null => {
  switch (type) {
    case ModalType.PurchaseLicences:
      return <PurchaseLicencesModal onClose={onClose} {...rest} />;
    case ModalType.Discounts:
      return <DiscountModal onClose={onClose} {...rest} />;
    case ModalType.InviteTeamMembers:
      return <InviteTeamMembersModal onClose={onClose} {...rest} />;
    case ModalType.UpwexApiKey:
      return <UpwexApiKeyModal onClose={onClose} {...rest} />;
    case ModalType.BuyExtraRequests:
      return <BuyExtraRequestsModal onClose={onClose} {...rest} />;
    case ModalType.SubscriptionCheckStatus:
      return <SubscriptionCheckStatusModal onClose={onClose} {...rest} />;
    case ModalType.SubscriptionCancellation:
      return <SubscriptionCancellationModal onClose={onClose} {...rest} />;
    case ModalType.Welcome:
      return <WelcomeModal onClose={onClose} {...rest} />;
  }

  return null;
};

ModalsFactory.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
