import moment from "moment";
import { Col, Divider, Drawer, Row } from "antd";
import "./job-info.scss";
// @ts-ignore
import { ReactComponent as ClockSVG } from "../../../assets/images/clock.svg";
// @ts-ignore
import { ReactComponent as MoneySVG } from "../../../assets/images/money.svg";
// @ts-ignore
import { ReactComponent as BidSVG } from "../../../assets/images/bid.svg";
// @ts-ignore
import { ReactComponent as LevelSVG } from "../../../assets/images/level.svg";
import { FormButton } from "../../../ui";
import { FaExternalLinkAlt } from "react-icons/fa";

interface IJobInfoProps {
  data: any;
  onClose: () => void;
  open?: boolean;
}

const baseClassName = "job-info";

const DescriptionItem = ({
  title,
  content,
  className,
}: {
  title?: string;
  content: any;
  className?: string;
}) => (
  <div className={`${baseClassName}__wrapper ${className}`}>
    {title && <p className={`${baseClassName}__label`}>{title}:</p>}
    {content}
  </div>
);

export const JobInfo = ({
  data,
  onClose,
  open = false,
}: IJobInfoProps): JSX.Element => {
  const { name, subscriptions, type } = data;

  const handleOpenProposalPage = (id: string) => {
    window.open(
      `https://www.upwork.com/nx/proposals/job/${id}/apply/`,
      "_blank"
    );
  };

  const handleOpenJobPage = (id: string) => {
    window.open(`https://www.upwork.com/jobs/${id}`, "_blank");
  };

  return (
    <Drawer title="Job Info" width={"70%"} onClose={onClose} open={open}>
      {data && (
        <div className={`${baseClassName}__container`}>
          <div className={`${baseClassName}__left`}>
            <Row>
              <Col span={6}>
                <div className={`${baseClassName}__stat`}>
                  <p>
                    <ClockSVG />
                    {moment(data.job_posted).fromNow()}
                  </p>
                  <span>Job posted</span>
                </div>
              </Col>
              <Col span={6}>
                <div className={`${baseClassName}__stat`}>
                  <p>
                    <MoneySVG />
                    {data.budget}
                  </p>
                  <span>{data.payment_type}</span>
                </div>
              </Col>
              <Col span={6}>
                <div className={`${baseClassName}__stat`}>
                  <p>
                    <LevelSVG />
                    {data.experience_level}
                  </p>
                  <span>Experience Level</span>
                </div>
              </Col>
              <Col span={6}>
                <div className={`${baseClassName}__stat`}>
                  <p>
                    <BidSVG />
                    {data.potential_bid}
                  </p>
                  <span>Potential Bid</span>
                </div>
              </Col>
            </Row>
            <Divider />
            <p className={`${baseClassName}__title`}>
              Lead Generation Intuition
            </p>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  content={data.lead_generation_intuition}
                  className={"active"}
                />
              </Col>
            </Row>
            <Divider />
            <p className={`${baseClassName}__title big`}>{data.title}</p>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  content={
                    <div
                      className={`${baseClassName}__desc`}
                      dangerouslySetInnerHTML={{
                        __html:
                          data?.description &&
                          data?.description?.replaceAll("\n", "<br>"),
                      }}
                    ></div>
                  }
                />
              </Col>
            </Row>
          </div>
          <div className={`${baseClassName}__right`}>
            <div className={`${baseClassName}__content actions`}>
              <FormButton
                theme="green"
                onClick={() => handleOpenJobPage(data.job_id)}
              >
                Job <FaExternalLinkAlt />
              </FormButton>
              <FormButton
                theme="green"
                onClick={() => handleOpenProposalPage(data.job_id)}
              >
                Proposal Page <FaExternalLinkAlt />
              </FormButton>
            </div>
            <Divider />
            <div className={`${baseClassName}__content`}>
              <p className={`${baseClassName}__title`}>About the client</p>
              <Row>
                <Col span={24}>
                  <DescriptionItem title="Hire rate" content={data.hire_rate} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DescriptionItem
                    title="Total spent"
                    content={data.total_spent}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DescriptionItem
                    title="Payment verification"
                    content={
                      data.payment_verification ? "Verified" : "Not verified"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DescriptionItem
                    title="Rating"
                    content={`${data.rating}(${data.total_reviews})`}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DescriptionItem
                    title="Member since"
                    content={moment(data.member_since).format("MMMM DD, YYYY")}
                  />
                </Col>
              </Row>
            </div>
            <Divider />
            <div className={`${baseClassName}__content`}>
              <p className={`${baseClassName}__title`}>Activity on this job</p>
              <Row>
                <Col span={24}>
                  <DescriptionItem
                    title="Interviewing"
                    content={data.interviews}
                  />
                </Col>
                <Col span={24}>
                  <DescriptionItem title="Proposals" content={data.proposals} />
                </Col>
                <Col span={24}>
                  <DescriptionItem
                    title="Invites sent"
                    content={data.invites}
                  />
                </Col>
                <Col span={24}>
                  <DescriptionItem title="Hired" content={data.hired} />
                </Col>
              </Row>
            </div>
            <Divider />
            <div className={`${baseClassName}__content`}>
              <p className={`${baseClassName}__title`}>Skills</p>
              <Row>
                <Col span={24}>
                  <div className={`${baseClassName}__skills`}>
                    {data &&
                      data.required_skills?.map((skill: string) => (
                        <div className={`${baseClassName}__skill-item`}>
                          {skill}
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};
